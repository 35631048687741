class GenericServiceClass {
    public socket: any;
    private namespace: string;
    constructor(_socket, _namespace) {
        this.socket = _socket;
        this.namespace = _namespace;
    }



    ShowPermissionsService() {
        return new Promise<any[]>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('SHOW_PERMISSIONS', function (err, result) {
                    if (err) { return reject(err); }
                    resolve(result);
                });

            } catch (err) {
                reject(err);
            }
        });
    }
    getPagingDetails(query: string, top: number, skip: number, orderby, reverse: boolean, collectionname: string,
        isMongoQuery: boolean = false) {
        return new Promise<any[]>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject("IO.Socket not connected to /" + this.namespace);
            }
            try {
                this.socket.emit("GETPAGINGDETAILS", query, top, skip, orderby, reverse, collectionname, isMongoQuery,
                    (err, result) => {
                        if (err) {
                            return reject(err);
                        }
                        resolve(result);
                    }
                );
            } catch (err) {
                reject(err);
            }
        });
    }
 
    get_permitted_users_on_prject(query: string, top: number | null, skip: number | null, orderby: string | null, reverse: boolean | null, collectionname: string | null) {
        return new Promise<any[]>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('GET_USERS_PERMITTED_ON_PROJECT', query, top, skip, orderby, reverse, collectionname, function (err, result) {
                    if (err) { return reject(err); }
                    resolve(result);
                });

            } catch (err) {
                reject(err);
            }
        });
    }

    getLisenseStatus() {
        return new Promise<any[]>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('CHECKlICENSE', function (err, result) {
                    if (err) { return reject(err); }
                    resolve(result);
                });

            } catch (err) {
                reject(err);
            }
        });
    }

    getWithProjection(query: string,projection:any, top: number | null, skip: number | null, orderby: string | null, reverse: boolean | null, collectionname: string | null) {
        return new Promise<any[]>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('GET_WITH_PROJECTION', query,projection, top, skip, orderby, reverse, collectionname, function (err, result) {
                    if (err) { return reject(err); }
                    resolve(result);
                });

            } catch (err) {
                reject(err);
            }
        });
    }

    get(query: string, top: number | null, skip: number | null, orderby: string | null, reverse: boolean | null, collectionname: string | null) {
        return new Promise<any[]>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('GET', query, top, skip, orderby, reverse, collectionname, function (err, result) {
                    if (err) { return reject(err); }
                    resolve(result);
                });

            } catch (err) {
                reject(err);
            }
        });
    }
    getAll(query: string, projection:any,  collectionname: string | null ) {
        return new Promise<any[]>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('GET_ALL', query,projection,  collectionname, function (err, result) {
                    if (err) { return reject(err); }
                    resolve(result);
                });

            } catch (err) {
                reject(err);
            }
        });
    }
    getWorkFlowsByID(query: string, top: number | null, skip: number | null, orderby: string | null, reverse: boolean | null, collectionname: string | null) {
        return new Promise<any[]>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('GET_WORKFLOWS_BY_PROJECTID', query, top, skip, orderby, reverse, collectionname, function (err, result) {
                    if (err) { return reject(err); }
                    resolve(result);
                });

            } catch (err) {
                reject(err);
            }
        });
    }
    query(query, projection, top: number | null, skip: number | null, orderby: string | null, reverse: boolean | null, collectionname: string) {
        return new Promise<any[]>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('QUERY', query, projection, top, skip, orderby, reverse, collectionname, function (err, result) {
                    if (err) { return reject(err); }
                    resolve(result);
                });

            } catch (err) {
                reject(err);
            }
        });
    }

    getbyid(id: string, collectionname: string) {
        return new Promise<any>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('GET', "_id eq '" + id + "'", 1, 0, null, false, collectionname, function (err, instance) {
                    if (err) { return reject(err); }
                    if (instance && instance.length > 0) {
                        resolve(instance[0]);
                    } else {
                        resolve(null);
                    }
                });

            } catch (err) {
                reject(err);
            }
        });
    }
    addForm(item: any, collectionname: string) {
        return new Promise<any>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('ADD_FORM', item, collectionname, function (err, result) {
                    if (err) { return reject(err); }
                    resolve(result);
                });

            } catch (err) {
                reject(err);
            }
        });
    }
    post(item: any, collectionname: string) {
        return new Promise<any>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('POST', item, collectionname, function (err, result) {
                    if (err) { return reject(err); }
                    resolve(result);
                });

            } catch (err) {
                reject(err);
            }
        });
    }
    postManyItemsService(items:any,collectionname: string) {
        return new Promise<any[]>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject("IO.Socket not connected to /" + this.namespace);
            }
            try {
                 
                this.socket.emit("POST_MANY",items, collectionname, 
                    (err, result) => {
                        if (err) {
                            return reject(err);
                        }
                        resolve(result);
                    }
                );
            } catch (err) {
                reject(err);
            }
        });
    }

    put(item: any, collectionname: string) {
        return new Promise<any>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('PUT', item, collectionname, function (err, result) {
                    if (err) { return reject(err); }
                    resolve(result);
                });

            } catch (err) {
                reject(err);
            }
        });
    }

    updateProps(item: any, collectionname: string) {
        return new Promise<any>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('UPDATEPROPS', item, collectionname, function (err, result) {
                    if (err) { return reject(err); }
                    resolve(result);
                });

            } catch (err) {
                reject(err);
            }
        });
    }
    delete(id: string, collectionname: string) {
        return new Promise<any>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('DELETE', id, collectionname, function (err) {
                    if (err) { return reject(err); }
                    resolve("deleted successfully");
                });

            } catch (err) {
                reject(err);
            }
        });
    }

    aggregate(aggregates: any, collectionname: string) {
        return new Promise<any[]>(async (resolve, reject) => {
            try {
                this.socket.emit('AGGREGATE', aggregates, collectionname, function (err, result) {
                    if (err) { return reject(err); }
                    resolve(result);
                });
            } catch (err) {
                reject(err);
            }
        });
    }

    isConnected() {
        if (!this.socket || !this.socket.connected) {
            return false;
        }
        return true;
    }
    
    async translateCron(cronEx: any) {
        return await new Promise<any>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('TRANSLATECRON', cronEx, function (message, err) {

                    if (err) { return reject(err); }
                    resolve(message);

                });

            } catch (err) {
                reject(err);
            }
        });
    }

    async nextRunCron(cronEx: any) {
        return await new Promise<any>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('NEXTCRON', cronEx, function (message, err) {

                    if (err) { return reject(err); }
                    resolve(message);

                });

            } catch (err) {
                reject(err);
            }
        });
    }

    addLogRecord2(ipAddress) {
        return new Promise<any[]>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('ADDLOGRECORD2', ipAddress, function (err, result) {
                    if (err) { return reject(err); }
                    resolve(result);
                });

            } catch (err) {
                reject(err);
            }
        });
    }
    createinstance(endpoint_id: string, target: string, data: any) {
        return new Promise<any>(async (resolve, reject) => {
            try {
                this.socket.emit('CREATEINSTANCE', endpoint_id, target, data, function (err, result) {
                    if (err) { return reject(err); }
                    resolve(result);
                });
            } catch (err) {
                reject(err);
            }
        });
    }

    encrypt(data: string) {
        return new Promise<string>(async (resolve, reject) => {
            try {
                this.socket.emit('ENCRYPT', data, function (err, result) {
                    if (err) { return reject(err); }
                    resolve(result);
                });
            } catch (err) {
                reject(err);
            }
        });
    }
    decrypt(data: string) {
        return new Promise<string>(async (resolve, reject) => {
            try {
                this.socket.emit('DECRYPT', data, function (err, result) {
                    if (err) { return reject(err); }
                    resolve(result);
                });
            } catch (err) {
                reject(err);
            }
        });
    }
}